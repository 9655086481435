.data-sheet {
  display: none;
  flex-wrap: nowrap;
  flex-direction: column;
  justify-content: flex-start;
  width: 992px;
  max-width: calc(100vw - 32px);
  height: calc(100vh - 72px);
  background-color: white;
  position: fixed;
  top: 72px;
  left: 50%;
  will-change: transform, opacity;
  z-index: 11;
  color: black;
  font-family: $roboto-regular;
  padding: 0 32px;
  box-sizing: border-box;
  overflow-y: auto;
  margin-bottom: 80px;

  > * {
    flex-grow: 0;
    flex-shrink: 0;
  }

  &.active {
    display: flex;
    transform: translateX(-50%) translateY(0);
    opacity: 1;
  }

  &.showing {
    display: flex;
    visibility: visible;
    animation: showDataSheet .5s ease;

    @keyframes showDataSheet {
      from {
        transform: translateX(-50%) translateY(100%);
        opacity: 0;
      }
      to {
        transform: translateX(-50%) translateY(0);
        opacity: 1;
      }
    }
  }

  &.hiding {
    animation: hideDataSheet .5s ease;

    @keyframes hideDataSheet {
      from {
        transform: translateX(-50%) translateY(0);
        opacity: 1;
      }
      to {
        transform: translateX(-50%) translateY(100%);
        opacity: 0;
      }
    }

  }

  .control-bar {
    display: flex;
    width: 100%;
    transform: translateX(16px);
    justify-content: flex-end;
    padding: 16px 0;
    position: sticky;
    top: 0;

    i, a {
      color: black;
      margin-left: 8px;
      position: relative;
      text-decoration: none;
    }
  }

  .heading {
    margin-bottom: 24px;

    h1 {
      font-size: 56px;
      margin: 8px 0;
    }

    h2 {
      font-size: 14px;
      font-family: $roboto-light;
      margin: 0;
      text-align: left;
      color: black;
    }
  }

  .section-container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .section {
    display: flex;
    flex-direction: column;
    width: calc(50% - 10px);
    margin-bottom: 16px;

    .subsection {
      width: 100%;
      margin-bottom: 16px;
    }

    &.small-cards {
      width: 100%;
      background-color: transparent;
      box-shadow: none;
    }

    &.chemicals, &.remarks {
      padding: 16px 0;
      box-sizing: border-box;

      .title {
        border-bottom: 1px solid transparentize(black, .5);
        padding-bottom: 8px;
      }

    }

    &.chemicals {

      .table {
        display: flex;
        flex-direction: column;

        .header {
          border-bottom: 1px solid transparentize(black, .5);
        }

        .header, .chemical {
          display: flex;
          text-transform: capitalize;
          line-height: 2;

          .cell {
            width: 25%;

            &.min {
              color: $light-blue;
            }

            &.max {
              color: $dark-blue;
            }
          }
        }



      }
    }



    &.remarks {
      p {
        font-size: 14px;
        font-family: $roboto-light;
        line-height: 1.5;
      }
    }

    .title {
      width: 100%;
      margin-bottom: 16px;
      font-size: 24px;
      text-transform: uppercase;
    }

    .items {
      display: flex;
      width: 100%;
      justify-content: space-between;

      .item {
        width: calc(25% - 15px);
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 16px;
        box-sizing: border-box;
        border-bottom: 1px solid transparentize(black, .5);

        .visual {
          height: 200px;
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-bottom: 16px;

          .colours {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;

            .colour {
              width: calc(50% - 8px);
              margin: 4px;
              display: flex;
              flex-direction: column;
              align-items: center;

              .preview {
                width: 44px; height: 44px;
                border-radius: 50%;
                margin-bottom: 8px;
              }

              .name {
                font-size: 12px;
                font-family: $roboto-light;
                text-align: center;
              }
            }
          }

          img {
            width: 100%; height: 100%;
            object-fit: contain;
          }
        }

        .name {
          font-size: 24px;
          text-transform: uppercase;
          margin-bottom: 8px;
          word-wrap: break-word;
        }

        .type {
          font-size: 14px;
          font-family: $roboto-light;
        }
      }


    }
  }

  @media screen and (max-width: 768px){
    flex-wrap: nowrap;

    .heading {
      h1.title {
        font-size: 32px;
      }
    }

    .section {
      width: 100%;


      .title {
        font-size: 20px;
      }

      &.small-cards {

        .items {
          flex-direction: column;

          .item {
            width: 100%;
            margin-bottom: 16px;
          }
        }
      }
    }
  }
}