@mixin icon-button (){

  &:after {
    content: ' ';
    left: 0; top: 0;
    position: absolute;
    display: inline-block;
    height: 100%; width: 100%;
    border-radius: 50%;
    background-color: rgba(0,0,0,0.075);
    transform: scale(0);
    transition: transform .2s ease;
    will-change: transform, opacity;
    cursor: pointer;
  }

  &.light {

    &:after {
      background-color: rgba(255,255,255,0.075);
    }
  }

  &:hover {
    &:after {
      transform: scale(1.3);
    }
  }

  &.clicked {
    /*hack to listen for pseudo element animation*/
    animation: iconButtonPressedPseudo .3s ease;

    &.light {
      &:after {
        background-color: rgba(255,255,255,0.16);
      }
    }

    &:after {
      background-color: rgba(0,0,0,0.16);
      animation: iconButtonPressed .3s ease;



      @keyframes iconButtonPressed {
        from {
          transform: scale(0);
          opacity: 1;
        }
        to {
          transform: scale(2);
          opacity: 0;
        }
      }

    }
  }
}

@mixin font-gradient(){
  background: -webkit-linear-gradient(left, $light-blue, $dark-blue);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

@mixin placeholder {
  &::-webkit-input-placeholder {@content}
  &:-moz-placeholder           {@content}
  &::-moz-placeholder          {@content}
  &:-ms-input-placeholder      {@content}
}