/*------------------------
 FONTS
 -------------------------*/
$fallback-fonts: 'HelveticaNeue', Helvetica, Arial, sans-serif;
$roboto-light: 'Roboto-Mono-Light', $fallback-fonts;
$roboto-regular: 'Roboto-Mono-Regular', $fallback-fonts;

/*------------------------
 COLORS
 -------------------------*/

$black              : #17171D;
$cool-brown         : #444050;
$light-blue         : #65BDD8;
$dark-blue          : #22798E;
$paper-grey         : #E8EBED;
$space-grey         : #727272;
$dark-grey          : #373343;
$data-sheet         : linear-gradient(to bottom, #292630, #444050);
$toggle-off-bg      : #7A7589;
$toggle-off-switch  : $dark-grey;
$bg-gradient        : linear-gradient(to bottom right, $light-blue, $dark-blue)