* {
  font-display: swap;
}

i, button {
  user-select: none;

  &.disabled {
    opacity: .5;
  }
}

button i {
  margin-left: 16px;
}

body {
  background: darken($light-blue, 20%);
  font-family: $roboto-regular;
}

sub {
  vertical-align: sub;
  font-size: smaller;
}

.container {
  z-index: 1;
  display: flex;
  min-height: calc(100vh - 72px);
  padding-top: 72px;
  margin: auto;


  &.centered-content {
    padding-top: 0;
    min-height: 100vh;
  }
}

.icon-button {
  @include icon-button();
}