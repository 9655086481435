.container.form {
  display: flex;
  flex-direction: column;
  width: 100vw;
  align-items: center;

  &.auth {
    background: linear-gradient(to bottom right, $light-blue, $dark-blue);
    justify-content: center;

    form {
      width: 400px;

      .form-group {
        margin-left: 0;
        width: 100%;

        input {
          width: 100%
        }
      }
    }
  }

  form {
      width: 992px;
      max-width: calc(100vw - 32px);
      display: flex;
      flex-direction: column;
      padding: 0 16px;

    &.material-form {
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;

      .form-title {
        width: 100%;
      }

      .form-group {
        width: calc(100% - 16px);
      }
    }

    .form-title {
      font-size: 24px;
      line-height: 2;
      color: white;
      flex-shrink: 0;
    }

    .form-section-title {
      width: 20%;
      margin-right: 80%;
      font-size: 20px;
      line-height: 1.5;
      color: white;
      flex-shrink: 0;
      white-space: nowrap;
    }

    .form-group {
      margin-left: 20%;
      padding: 16px 0;
      position: relative;
      display: flex;

      &.has-error {

        input {
          border-bottom: 2px solid red;
        }

      }

      input {
        width: 100%;
        background-color: transparent;
        border: none;
        height: 32px;
        padding: 6px 0;
        outline: none;
        border-bottom: 1px solid white;
        font-family: $roboto-light;
        font-size: 20px;
        text-indent: 0;
        color: white;
        line-height: 32px;
        opacity: .5;

        ~ label {
          opacity: .5;
          position: absolute;
          bottom: 0;
          left: 0;
          font-size: 20px;
          color: white;
          transform: translateY(calc(-100% - 6px));
          transition: transform .2s ease, font-size .2s ease;
        }

        &:focus {
          opacity: 1;

          ~ label {
            opacity: 1;
            font-size: 12px;
            transform: translateY(calc(-100% - 50px));
          }
        }

        &.has-value {

          ~ label {
            font-size: 12px;
            transform: translateY(calc(-100% - 50px));
          }
        }
      }

      .checkbox {
        display: flex;
        position: relative;
        align-items: center;
        height: 32px;
        color: white;

        &.checked {
          i.checked {
            transform: scale(1);
            opacity: 1;
          }
        }

        i {
          top: 4px;
          position: absolute;
          transform-origin: 50% 50%;
          transition: transform .15s ease, opacity .15s ease;
          cursor: pointer;
          @include icon-button();

          &.checked {
            transform: scale(0);
            opacity: 0;
          }
        }

        label {
          margin-left: 32px;
        }
      }

      .dropdown {
        width: 100%;
        background-color: transparent;
        height: 32px;
        padding: 6px 0;
        border-bottom: 1px solid white;
        font-family: $roboto-light;
        font-size: 20px;
        color: white;
        position: relative;
        opacity: .5;

        &.multi-value-dropdown {
          .dropdown-item {

            .check-icon {
              display: none;
            }

            &.added {
              color: rgba(0,0,0,.24);

              .check-icon {
                display: block;
              }

              .add-icon {
                display: none;
              }
            }
          }
        }

        &.open {
          opacity: 1;

          .dropdown-label {
            i {
              transform:rotate(180deg);
            }
          }
          .dropdown-items {
            transform: scaleY(1);

            .dropdown-item {
              opacity: 1;
            }
          }
        }

        .dropdown-label {
          display: flex;
          justify-content: space-between;
          cursor: pointer;
          z-index: 1;

          .value {
            text-transform: capitalize;
          }

          i {
            transform: rotate(0);
            transition: transform .2s ease;
            will-change: transform;
          }
        }

        .dropdown-items {
          top: 100%;
          position: relative;
          background-color: white;
          color: $cool-brown;
          transform-origin: 0 0;
          transform: scaleY(0);
          transition: transform  .2s ease;
          will-change: transform;
          z-index: 2;

          .dropdown-item {
            padding: 16px;
            text-transform: capitalize;
            cursor: pointer;
            opacity: 0;
            transition: opacity .2s ease;
            will-change: opacity;
            display: flex;

            i {
              margin-left: auto;
            }

            &.selected {
              font-family: $roboto-regular;
            }

            &:nth-child(2n){
              background-color: darken(white, 5%);
            }
          }
        }
      }

      textarea {
        background-color: transparent;
        border: none;
        outline: none;
        border-bottom: 1px solid white;
        width: 100%;
        line-height: 32px;
        font-family: $roboto-light;
        font-size: 16px;
        color: white;
        opacity: .5;

        ~ label {
          opacity: .5;
          position: absolute;
          left: 0;
          bottom: 0;
          font-size: 20px;
          color: white;
          transform: translateY(calc(-100% - 6px));
          transition: transform .2s ease, font-size .2s ease;
        }

        &:focus {
          opacity: 1;
          ~ label {
            opacity: 1;
            transform: translateY(6px);
            font-size: 12px;

          }
        }

        &.has-value {

          ~ label {
            opacity: 1;
            transform: translateY(6px);
            font-size: 12px;
          }
        }

      }
    }

    .light-button {
      margin-top: 0;
      background-color: white;
      color: $dark-blue;
      display: flex;
      justify-content: center;
    }

    .link-button {
      color: white;
      margin: 16px auto;
      text-decoration: none;
    }
  }

  .checkbox-form-group {
    display: flex;
    flex-direction: column;

    &.form-group {
      .input-container {
        display: flex;
        align-items: center;

        input {
          position: relative;
        }

        input {
          width: auto;
        }

        p {
          margin-left:  16px;
          color: $paper-grey;
          font-size: 20px;
        }

      }
    }

    p {
      color: white;
      font-size: 12px;
    }


  }
}

.color-dropdown {
  .dropdown-item {
    .color-preview {
      height: 24px;
      width: 24px;
      margin-right: 32px;
    }
  }
}

.added-color-list {
  display: flex;
  flex-wrap: wrap;
  margin-left: 20%;
  width: 80%;
  color: white;

  .added-color {
    display: flex;
    align-items: center;
    background-color: $paper-grey;
    padding: 8px;
    margin: 0 8px 8px 0;
    color: black;

    .name {
      margin: 0 16px;
    }

    .color-preview {
      height: 24px;
      width: 24px;
    }

    i {
      position: relative;
      @include icon-button();
    }
  }

}

.image-selector {
  display: flex;
  flex-wrap: wrap;

  .btn-upload-image {
    height: 132px;
    width: 132px;
    margin: 0 8px 8px 0;
    border: 4px dashed white;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    position: relative;

    &.selected {
      background-color: white;

      i {
        display: none;
      }

      img {
        display: flex;
      }
    }

    img {
      padding: 16px;
      height: 100%; width: 100%;
      object-fit: contain;
      box-sizing: border-box;
      display: none;
    }

    #upload-input {
      height: 100%; width: 100%;
      opacity: 0;
      position: absolute;
      top: 0; left: 0;
      cursor: pointer;

    }

    i {
      color: white;
      font-size: 32px;
    }
  }

  .image {
    width: 100px;
    height: 100px;
    padding: 16px;
    margin: 0 8px 8px 0;
    background-color: transparent;
    border-radius: 4px;
    transition: background-color .2s ease;
    cursor: pointer;

    &.selected {
      background-color: $paper-grey;

      img {
        transition: transform .2s ease, opacity .2s ease;
        transform: scale(1);
        opacity: 1;
      }
    }

    img {
      width: 100%; height: 100%;
      object-fit: contain;
      transform: scale(0.8);
      opacity: .5;
    }
  }
}

.modal-form {
  .btn-modal-image-upload {
    width: 100%; height: 300px;
    border: 4px dashed white;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    position: relative;

    &.selected {
      background-color: white;

      img {
        display: flex;
        width: calc(100% - 32px);
        height: calc(100% - 32px);
        align-self: center;
        object-fit: contain;
      }

      .btn-clear-modal-image-upload {
        display: flex;
      }

      .camera-icon {
        display: none;
      }
    }

    img {
      display: none;
    }

    input {
      height: 100%; width: 100%;
      opacity: 0;
      z-index: 2;
      position: absolute;
      top: 0;
      cursor: pointer;
    }

    .btn-clear-modal-image-upload {
      display: none;
      z-index: 3;
      position: absolute;
      top: 16px;
      right: 16px;
      color: $dark-blue;
    }
  }
}


