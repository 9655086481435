@font-face {
  font-family: 'Roboto-Mono-Light';
  src: url('/css/fonts/roboto-mono_light.eot'), /* IE9 Compat Modes */
  url('/css/fonts/roboto-mono_light.woff') format('woff'), /* Pretty Modern Browsers */
  url('/css/fonts/roboto-mono_light.ttf')  format('truetype'), /* Safari, Android, iOS */
  url('/css/fonts/roboto-mono_light.svg#RobotoMono-Light') format('svg'); /* Legacy iOS */
}

@font-face {
  font-family: 'Roboto-Mono-Regular';
  src: url('/css/fonts/roboto-mono_regular.eot'),
  url('/css/fonts/roboto-mono_regular.woff') format('woff'),
  url('/css/fonts/roboto-mono_regular.ttf')  format('truetype'),
  url('/css/fonts/roboto-mono_regular.svg#RobotoMono-Regular') format('svg');
}

@font-face {
    font-family: 'Sarpanch-Regular';
    src: url('/css/fonts/sarpanch/Sarpanch-Regular.ttf')  format('truetype');
}

@font-face {
    font-family: 'Sarpanch-Medium';
    src: url('/css/fonts/sarpanch/Sarpanch-Medium.ttf')  format('truetype');
}

@font-face {
    font-family: 'Sarpanch-Bold';
    src: url('/css/fonts/sarpanch/Sarpanch-Bold.ttf')  format('truetype');
}

@font-face {
    font-family: 'Sarpanch-ExtraBold';
    src: url('/css/fonts/sarpanch/Sarpanch-ExtraBold.ttf')  format('truetype');
}


@font-face {
    font-family: 'Sarpanch-Black';
    src: url('/css/fonts/sarpanch/Sarpanch-Black.ttf')  format('truetype');
}





