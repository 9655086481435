.snackbar {
  position: fixed;
  bottom: 0; left: 50%;
  transform: translateX(-50%) translateY(100%);
  max-width: calc(100vw - 32px);
  background-color: $cool-brown;
  color: white;
  padding: 16px 16px 16px 24px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  &.light {
    background-color: $cool-brown;

    button {
      color: $cool-brown;
    }
  }

  &.open {
    animation: openSnackbar .3s ease forwards;

    @keyframes openSnackbar {
      from {
        transform: translateX(-50%) translateY(100%);
      }
      to {
        transform: translateX(-50%) translateY(0%);
      }
    }
  }

  &.close {
    animation: closeSnackbar .3s ease forwards;

    @keyframes closeSnackbar {
      from {
        transform: translateX(-50%) translateY(0%);
      }
      to {
        transform: translateX(-50%) translateY(100%);
      }
    }
  }

  .snackbar-content {
    font-size: 14px;
  }

  .dismiss-snackbar {
    border: none;
    outline: none;
    text-transform: uppercase;
    box-shadow: none;
    color: $light-blue;
    margin-top: 0;
    margin-left: 16px;
    margin-bottom: 0;
    padding: 8px 16px;
    font-family: $roboto-regular;
    font-size: 16px;
    cursor: pointer;
    background: none;
    align-self: center;

    &:hover {
      background: none;
    }
  }

}