.about-us {
  width: 992px;
  max-width: calc(100vw - 32px);
  margin: auto;
  flex-direction: column;
  color: white;

  .slider {
    display: flex;
    position: relative;

    .slider-control {
      position: absolute;
      top: 50%;
      font-size: 56px;
      @include icon-button();
      z-index: 2;

      &.btn-slider-left {
        left: 56px;
        transform: translateX(-100%) translateY(-50%);
      }

      &.btn-slider-right {
        right: 56px;
        transform: translateX(100%) translateY(-50%);
      }
    }

    .slides {
      width: 100%;
      min-height: calc(100vh - (56px * 1.5) - 72px);
      position: relative;
      display: flex;
      align-items: center;
      padding: 16px;
      z-index: 1;

      .slide {
        min-height: 200px;
        width: 100%;
        position: absolute;
        top: 40%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        transform-origin: 50% 50%;
        transform: translateY(-50%);
        visibility: hidden;
        flex-shrink: 0;

        &.active {
          visibility: visible;
          opacity: 1;
        }

        &.slide-in-left {
          visibility: visible;
          animation: slideInLeft .3s ease;

          @keyframes slideInLeft {
            from {
              transform: translateX(100%) translateY(-50%) scale(0.8);
              opacity: 0;
            }
            to {
              transform: translateX(0) translateY(-50%) scale(1);
              opacity: 1;
            }
          }
        }

        &.slide-out-left {
          visibility: visible;
          animation: slideOutLeft .3s ease;

          @keyframes slideOutLeft {
            from {
              transform: translateX(0) translateY(-50%) scale(1);
              opacity: 1;
            }
            to {
              transform: translateX(100%) translateY(-50%) scale(0.8);
              opacity: 0;
            }
          }
        }

        &.slide-in-right {
          visibility: visible;
          animation: slideInRight .3s ease;

          @keyframes slideInRight {
            from {
              transform: translateX(-100%) translateY(-50%) scale(0.8);
              opacity: 0;
            }
            to {
              transform: translateX(0) translateY(-50%) scale(1);
              opacity: 1;
            }
          }
        }

        &.slide-out-right {
          visibility: visible;
          animation: slideOutRight .3s ease;

          @keyframes slideOutRight {
            from {
              transform: translateX(0) translateY(-50%) scale(1);
              opacity: 1;
            }
            to {
              transform: translateX(-100%) translateY(-50%) scale(0.8);
              opacity: 0;
            }
          }
        }

        i {
          font-size: 72px;
          margin: 24px 0;
        }
      }
    }


  }

  h1 {
    font-size: 56px;
    line-height: 1.5;
    text-align: center;
    text-transform: uppercase;
  }

  h2 {
    font-size: 32px;
    line-height: 2;
  }

  p, li {
    font-size: 16px;
    font-family: $roboto-light;
    line-height: 1.5;
  }

  li {
    line-height: 2;
    list-style-type: square;
  }

  a {
    color: lighten($light-blue, 40%);
  }

  @media screen and (max-width: 500px){
    
    .slider-control {
      visibility: hidden;
    }
  }
}

