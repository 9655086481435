.existing-chemical-list {
  display: flex;
  flex-direction: column;
  width: 992px;
  max-width: calc(100vw - 32px);
  color: white;
  margin: 24px 0;

  .title {
    font-size: 24px;
    line-height: 1.5;
    margin-bottom: 16px;
  }

  .header {
    display: flex;
    justify-content: space-between;
    font-family: $roboto-regular;
    height: 32px;
    align-items: center;
    .column {
      width: 30%;

      &.first-probe {
        color: $light-blue;
      }
    }

    .gutter {
      width: 10%;
    }
  }

  .items {
    display: flex;
    flex-direction: column;
    font-family: $roboto-light;

    .item {
      display: flex;
      justify-content: space-between;
      height: 32px;
      align-items: center;

      &:hover {
        .gutter {
          opacity: 1;
        }
      }


      .column {
        width: 30%;

        &.first-probe {
          color: $light-blue;
        }
      }

      .gutter {
        width: 10%;
        opacity: 0.3;
        display: flex;
        align-items: flex-end;

        a {
          text-decoration: none;
          color: white;
          position: relative;
        }
      }
    }
  }
}