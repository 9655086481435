@import 'fonts';
@import 'material-icons';
@import 'variables';
@import 'reset';
@import 'mixins';
@import 'general';

@import 'modules/nav';
@import 'modules/obfuscator';
@import 'modules/drawer';
@import 'modules/index';
@import 'modules/home';
@import 'modules/ripple';
@import 'modules/materials';
@import 'modules/about-us';
@import 'modules/contact';
@import 'modules/loader';
@import 'modules/form';
@import 'modules/snackbar';
@import 'modules/alert';
@import 'modules/material-manager';
@import 'modules/create-chemical';
@import 'modules/data-sheet';
