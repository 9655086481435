.material-manager {
  width: 100vw;
  margin-top: 72px;
  min-height: calc(100vh - 72px);
  display: flex;
  flex-direction: column;

  .fab-container {
    position: fixed;
    bottom: 32px; right: 32px;
    display: flex;
    z-index: 2;
  }

  .create-item-button,
  .delete-unused-images-button,
  .update-catalogue-button,
  .download-catalogue-button {
    margin-left: 16px;
    padding: 16px;
    color: white;
    background-color: $cool-brown;
    border-radius: 50%;
    cursor: pointer;
    text-decoration: none;
  }

  .delete-unused-images-button {
    background-color: darkred;
  }

  .update-catalogue-button {
    background-color: $dark-grey;

    &--updating {
      animation: updating .3s linear infinite;
      pointer-events: none;

      @keyframes updating {
        from {
          transform: rotate(0deg);
        }
        to {
          transform: rotate(180deg);
        }
      }
    }
  }

  .tabs {
    display: flex;
    height: 48px;
    justify-content: center;
    position: relative;

    .tab {
      height: 100%;
      min-width: 160px; max-width: 264px;
      box-sizing: border-box;
      display: flex;
      justify-content: center;
      padding: 20px 12px;
      text-transform: uppercase;
      transition: color .2s ease;
      cursor: pointer;
      color: rgba(255, 255, 255, 0.5);

      &.active {
        color: rgba(255, 255, 255, 1);
      }

    }

    .tab-indicator {
      position: absolute;
      bottom: 0;
      left: 0;
      height: 2px;
      width: 100%;
      transform-origin: 0 0;
      background-color: white;
      transition: transform .3s ease;

    }
  }

  .sections {
    width: 100%;
    position: relative;

    .section {
      display: none;
      width: 100%;
      margin: auto;
      visibility: hidden;
      transform: translateY(32px);
      opacity: 0;
      transition: transform .2s ease, opacity .2s ease;

      .content {
        width: 992px;
        max-width: calc(100vw - 32px);
        min-height: 100px;
        margin: auto;
        padding: 24px 0;

        .list {
          display: flex;
          flex-direction: column;

          .item {
            height: 40px;
            padding: 8px 16px;
            align-items: center;
            border-bottom: .25px solid rgba(255, 255, 255, 0.15);
            display: flex;

            .symbol, .preview {
              height: 32px;
              width: 32px;
              border-radius: 50%;
              text-align: center;
              line-height: 32px;
              background-color: $dark-blue;
              color: white;
              margin-right: 24px;
            }

            img {
              height: 40px; width: 40px;
              object-fit: contain;
              margin-right: 16px;
            }

            .info {
              display: flex;
              flex-direction: column;
              color: white;

              .caption {
                display: flex;
              }

                .name {
                  font-size: 20px;
                  line-height: 1.2;
                  text-transform: capitalize;
                }

                .materials {
                  font-family: $roboto-light;
                }
            }

            .actions {
              margin-left: auto;
              display: flex;

              a, i {
                text-decoration: none;
                color: white;
                position: relative;
                margin: 0 8px;
              }
            }
          }
        }
      }

      &.active {
        display: block;
        visibility: visible;
        opacity: 1;
        transform: translateY(0);
      }
    }
  }

  .tooltip {

    &::before {
      content: attr(data-tooltip) " ";
      display: inline-block;
      position: absolute;
      left: 0; top: 50%;
      transform: translateY(-50%) translateX(calc(-100% - 4px));
      padding: 4px;
      background: $cool-brown;
      color: white;
      opacity:0;
      font-family: $roboto-light;
      font-size: 14px;
      padding: 6px 12px;
      transition: transform .2s ease, opacity .2s ease;
      pointer-events: none;
    }

    &:hover {
      &::before {
        transform: translateY(-50%) translateX(calc(-100% - 12px));
        opacity: 1;
      }
    }
  }

  @media screen and (max-width: 768px){
    .tabs {
      width: calc(100% - 32px);
      margin-left: 16px;
      justify-content: space-between;

      .tab {
        min-width: 80px;
        max-width: 22%;
        font-size: 14px;
      }
    }

    .material-list {
      .material-item {
        padding: 8px 0;

        .info {
          .name {
            font-size: 14px;
          }

          .caption {
            .group {
              font-size: 12px;
            }

            .colours {
              .colour {
                height: 14px; width: 14px;
              }
            }
          }

          .bulk-density {
            font-size: 12px;
          }
        }
      }
    }

    .sections {
      .section {
        .content {
          .list {
            .item {
              padding: 8px 0;
            }
          }
        }
      }
    }
  }
}

.material-list {
  display: flex;
  flex-direction: column;

  &__group {
    display: flex;
    flex-direction: column;

    .material-list__expand-material-group {
      transform: rotate(0deg);
    }

    .material-list__group-materials {
      display: none;
    }

    &--expanded {

      .material-list__expand-material-group {
        transform: rotate(180deg);
      }

      .material-list__group-materials {
        display: flex;
      }
    }
  }

  &____group-material-count {
    margin-left: 16px;
  }


  &__group-header {
    display: flex;
    margin-left: 16px;
    padding: 16px;
    align-items: center;
    color: $dark-blue;
    background: $paper-grey;
  }

  &__expand-material-group {
    margin-left: auto;

    @include icon-button();
  }

  &__group-name {
    font-weight: 500;
  }

  &__group-materials {
    flex-direction: column;
  }

  .material-item {
    display: flex;
    height: 72px;
    padding: 8px 16px;
    align-items: center;
    border-bottom: .25px solid rgba(255, 255, 255, 0.15);

    img {
      height: 40px; width: 40px;
      margin-right: 16px;
      object-position: center;
      object-fit: contain;
    }

    .info {
      color: white;
      display: flex;
      justify-content: space-between;
      flex-direction: column;

      .name {
        font-size: 16px;
        text-transform: capitalize;
        line-height: 1.5;
      }

      .caption {
        display: flex;
        align-items: center;

        > div {
          margin-right: 4px;
          line-height: 1;
        }
        .group {
          font-size: 16px;
          line-height: 1.5;
          font-family: $roboto-light;
          text-transform: capitalize;
        }

        .colours {
          font-size: 20px;
          display: flex;

          .colour {
            height: 20px;
            width: 20px;
            margin: 0 8px 0 0;
          }
        }

        .bulk-density {
          font-family: $roboto-light;
          font-size: 14px;
          line-height: 1.5;
        }
      }
    }

    .action-buttons {
      margin-left: auto;

      a {
        text-decoration: none;
        color: white;
      }

    }
  }
}

.group, .material-item {
  &__change-order-actions {
    display: flex;

    > a {
      height: 24px;
      width: 24px;
      color: white;
      position: relative;
      margin-right: 8px;
      text-decoration: none;

      @include icon-button();
    }
  }
}
