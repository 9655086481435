.container {

  .hero-bg {
    height: 100vh;
    width: 100vw;
    position: fixed;
    top: 0;
    z-index: -1;


    .filter-container {
      display: none;
    }

    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
      animation: scalingHero 10s ease alternate infinite;
      filter:  url(#duotone);

      @keyframes scalingHero {
        from {
          transform: scale(1);
        }
        to {
          transform: scale(1.05);
        }
      }

    }

    //.hero-overlay {
    //  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, .5) 20%, rgba(0, 0, 0, .1));
    //  background-color: transparentize($dark-blue, .5);
    //  width: 100%;
    //  height: 100%;
    //  position: absolute;
    //  top: 0;
    //}
  }

  .elements {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;

    img {
      max-width: 200px;
      width: 30vmin;
      object-fit: contain;
    }

    h1 {
      font-family: $roboto-regular;
      font-size: 32px;
      line-height: 1.5;
      text-align: center;
      color: white;

      &.dash {
        font-size: 48px;
      }
    }
  }
    button {
      margin-top: 24px;
      display: flex;
      border: none;
      outline: none;
      box-shadow: 0 5px 5px rgba(0, 0, 0, .3);
      text-transform: uppercase;
      font-size: 18px;
      font-family: $roboto-regular;
      background-color: $cool-brown;
      color: white;
      padding: 16px 24px;
      cursor: pointer;
      position: relative;
      overflow: hidden;

      &.btn-explore-now {
        background: $bg-gradient;
      }

      a {
        text-decoration: none;
        color: white;
      }
    }
}