nav {
  height: 72px; width: 100vw;
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  align-items: center;
  padding: 0 16px;
  z-index: 3;
  position: fixed;
  top: 0;

  .left-interactive-container {
    position: relative;
    height: 72px;
    width: 100%;
    display: flex;

    .brand {
      .logo {
        height: 42px;
        margin-right: 24px;
      }
    }
    .interactive-nav-item {
      display: flex;
      align-items: center;
      position: absolute;
      top: 0;
      height: 100%;
      visibility: hidden;
      text-decoration: none;

      &.active {
        visibility: visible;
      }

      i {
        margin-right: 24px;
        color: white;
        position: relative;
        @include icon-button();
      }

      .name {
        font-family: 'Sarpanch-Regular', sans-serif;
        font-size: 20px;
        color: white;
        white-space: nowrap;
        text-transform: uppercase;

        .superscript {
          vertical-align: super;
        }
      }

      &.showing {
        visibility: visible;
        animation: showingInteractiveItem .2s ease;
        @keyframes showingInteractiveItem {
          from {
            transform: translateY(-100%);
          }
          to {
            visibility: visible;
            transform: translateY(0%);
          }
        }
      }

      &.hiding {
        visibility: visible;
        animation: hidingInteractiveItem .2s ease;
        @keyframes hidingInteractiveItem {
          from {
            visibility: visible;
            transform: translateY(0%);
            opacity: 1;
          }
          to {
            visibility: hidden;
            transform: translateY(100%);
            opacity: 0;
          }
        }
      }
    }
  }

  .right-interactive-container {
    display: flex;

    .btn-open-drawer {
      position: relative;
      @include icon-button();
      color: white;
      margin-left: 16px;
    }

    .language-dropdown {
      height: 24px; width: 24px;
      position: relative;

      .btn-select-language {
        @include icon-button();
        color: white;
      }

      .dropdown-items {
        position: absolute;
        top: calc(100% + 16px); left: 50%;
        transform: translateX(-50%) translateY(-16px);
        transition: transform .2s ease, opacity .2s ease;
        visibility: hidden;
        opacity: 0;
        background-color: white;

        &.open {
          transform: translateX(-50%) translateY(0);
          visibility: visible;
          opacity: 1;
        }

        &:before {
          content: '';
          display: flex;
          height: 10px; width: 10px;
          position: absolute;
          top: 0; left: 50%;
          transform-origin: 50% 50%;
          background-color: white;
          transform: translateX(-50%) translateY(-50%) rotate(45deg);
        }

        .dropdown-item {
          padding: 12px 24px;
          background-color: white;
          position: relative;
          cursor: pointer;
          user-select: none;
          display: flex;
          text-decoration: none;
          color: black;
          white-space: nowrap;
          text-transform: capitalize;
          cursor: pointer;

          &.active {
            background-color: lighten($light-blue, 30%);
            color: $dark-blue;
          }

          &:hover {
            background-color: lighten($light-blue, 35%);

            &:first-child {
              &.active {
                &:before {
                  content: '';
                  display: flex;
                  height: 10px; width: 10px;
                  position: absolute;
                  top: 0; left: 50%;
                  transform-origin: 50% 50%;
                  background-color: lighten($light-blue, 35%);
                  transform: translateX(-50%) translateY(-50%) rotate(45deg);
                }
              }
            }
          }

          &:first-child {
            &.active {
              &:before {
                content: '';
                display: flex;
                height: 10px; width: 10px;
                position: absolute;
                top: 0; left: 50%;
                transform-origin: 50% 50%;
                background-color: lighten($light-blue, 30%);
                transform: translateX(-50%) translateY(-50%) rotate(45deg);
              }
            }
          }
        }
      }
    }
  }
}