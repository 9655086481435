.drawer {
  width: 256px; height: 100vh;
  max-width: 83vw;
  background-color: white;
  position: fixed;
  top: 0; right: 0;
  transform: translateX(100%);
  transition: transform .3s ease;
  will-change: transform;
  z-index: 12;

  &.active {
    transform: translateX(0);
  }

  .header {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 32px;

    .btn-close-drawer {
      margin: 24px 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      align-self: flex-end;
      cursor: pointer;
      position: relative;

      @include icon-button();
    }

    .badge {
      height: 120px; width: 120px;
      object-fit: contain;
    }

    .name {
      font-family: 'Sarpanch-Bold', sans-serif;
      margin: 16px 0;
      font-size: 16px;
      @include font-gradient();
    }

  }

  .drawer-items {
    display: flex;
    flex-direction: column;

    .drawer-item {
      height: 56px;
      display: flex;
      padding: 16px;
      align-items: center;
      box-sizing: border-box;
      text-decoration: none;
      color: black;

      &.active {
        background-color: transparentize($dark-blue, 0.86);
        color: $dark-blue;
      }


      i {
        margin-right: 32px;
      }

      .title {
        font-family: 'Sarpanch-Regular', sans-serif;
      }

    }
  }
}
