.alert, .modal-form {
  position: fixed;
  top: 50%; left: 50%;
  transform: translateX(-50%) translateY(-50%) scale(0);
  opacity: 0;
  transition: transform .3s ease, opacity .3s ease;
  background-color: $dark-blue;
  min-width: 300px;  max-width: calc(100vw - 32px);
  min-height: 150px; max-height: calc(100vh - 32px);
  padding: 24px;
  color: white;
  display: flex;
  flex-direction: column;
  z-index: 3;

  &.open {
    transform: translateX(-50%) translateY(-50%) scale(1);
    opacity: 1;
  }

  .title {
    font-size: 24px;
    margin-bottom: 8px;
  }

  .text {
    font-family: $roboto-light;
  }

  form {
    display: flex;
    flex-direction: column;

    .form-group {
      padding: 16px 0;
      position: relative;
      display: flex;

      &.with-colour-preview {

        .colour-preview {
          width: 32px;
          height: 32px;
          border-radius: 50%;
          margin-right: 8px;
          background-color: $paper-grey;
        }

        input {
          width: calc(100% - 40px);

          ~ label {
            transform: translateY(calc(-100% - 6px)) translateX(40px);
          }

          &:focus, &.has-value {
            ~ label {
              transform: translateY(calc(-100% - 50px))  translateX(40px);
            }
          }
        }
      }

      &.has-error {

        input {
          border-bottom: 2px solid red;
        }
      }

      input {
        width: 100%;
        background-color: transparent;
        border: none;
        height: 32px;
        padding: 6px 0;
        outline: none;
        border-bottom: 1px solid white;
        font-family: $roboto-light;
        font-size: 20px;
        text-indent: 0;
        color: white;
        line-height: 32px;
        opacity: .5;

        ~ label {
          opacity: .5;
          position: absolute;
          bottom: 0;
          left: 0;
          font-size: 20px;
          color: white;
          transform: translateY(calc(-100% - 6px));
          transition: transform .2s ease, font-size .2s ease;
        }

        &:focus {
          opacity: 1;

          ~ label {
            opacity: 1;
            font-size: 12px;
            transform: translateY(calc(-100% - 50px));
          }
        }

        &.has-value {

          ~ label {
            font-size: 12px;
            transform: translateY(calc(-100% - 50px));
          }
        }
      }

      .checkbox {
        display: flex;
        position: relative;
        align-items: center;
        height: 32px;
        color: white;

        &.checked {
          i.checked {
            transform: scale(1);
            opacity: 1;
          }
        }

        i {
          top: 4px;
          position: absolute;
          transform-origin: 50% 50%;
          transition: transform .15s ease, opacity .15s ease;
          cursor: pointer;
          @include icon-button();

          &.checked {
            transform: scale(0);
            opacity: 0;
          }
        }

        label {
          margin-left: 32px;
        }
      }

      .dropdown {
        width: 100%;
        background-color: transparent;
        height: 32px;
        padding: 6px 0;
        border-bottom: 1px solid white;
        font-family: $roboto-light;
        font-size: 20px;
        color: white;
        position: relative;
        opacity: .5;

        &.multi-value-dropdown {
          .dropdown-item {

            .check-icon {
              display: none;
            }

            &.added {
              color: rgba(0,0,0,.24);

              .check-icon {
                display: block;
              }

              .add-icon {
                display: none;
              }
            }
          }
        }

        &.open {
          opacity: 1;

          .dropdown-label {
            i {
              transform:rotate(180deg);
            }
          }
          .dropdown-items {
            transform: scaleY(1);

            .dropdown-item {
              opacity: 1;
            }
          }
        }

        .dropdown-label {
          display: flex;
          justify-content: space-between;
          cursor: pointer;
          z-index: 1;

          .value {
            text-transform: capitalize;
          }

          i {
            transform: rotate(0);
            transition: transform .2s ease;
            will-change: transform;
          }
        }

        .dropdown-items {
          top: 100%;
          position: relative;
          background-color: white;
          color: $cool-brown;
          transform-origin: 0 0;
          transform: scaleY(0);
          transition: transform  .2s ease;
          will-change: transform;
          z-index: 2;

          .dropdown-item {
            padding: 16px;
            text-transform: capitalize;
            cursor: pointer;
            opacity: 0;
            transition: opacity .2s ease;
            will-change: opacity;
            display: flex;

            i {
              margin-left: auto;
            }

            &.selected {
              font-family: $roboto-regular;
            }

            &:nth-child(2n){
              background-color: darken(white, 5%);
            }
          }
        }
      }

      textarea {
        background-color: transparent;
        border: none;
        outline: none;
        border-bottom: 1px solid white;
        width: 100%;
        line-height: 32px;
        font-family: $roboto-light;
        font-size: 16px;
        color: white;
        opacity: .5;

        ~ label {
          opacity: .5;
          position: absolute;
          bottom: 0;
          font-size: 20px;
          color: white;
          transform: translateY(calc(-100% - 6px));
          transition: transform .2s ease, font-size .2s ease;
        }

        &:focus {
          opacity: 1;
          ~ label {
            opacity: 1;
            transform: translateY(6px);
            font-size: 12px;

          }
        }

      }
    }

  }

  .buttons {
    margin-top: auto;
    display: flex;
    width: 100%;
    justify-content: flex-end;

    button {

      border: none;
      background: none;
      outline: none;
      height: 40px;
      text-align: center;
      text-transform: uppercase;
      color: white;
      font-size: 16px;
      padding: 12px 24px;
      font-family: $roboto-light;
      margin-left: 8px;
      cursor: pointer;
      position: relative;
      overflow: hidden;
      box-shadow: none;

      &.confirm-action {
        background-color: white;
        color: $dark-blue;
      }

      &.cancel-action {
        color: white;
      }

    }

  }




}