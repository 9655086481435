.layout-v2 {
    background: $black;

    #particles-js {
        position: fixed;
        top: 0;
        left: 0;
        height: 100vh; width: 100vw;
        z-index: -1;
    }

    nav {
        height: 72px;


        .left-interactive-container {

            .interactive-nav-item {

                i {
                    color: $light-blue;
                }

                .name {
                    @include font-gradient();
                    font-family: 'Sarpanch-ExtraBold', sans-serif;
                    color: $light-blue;

                    span {
                        font-family: 'Sarpanch-Regular', sans-serif;
                    }
                }
            }
        }
    }

    button {
        display: inline-flex;
        align-self: flex-end;
        margin: 32px 0 16px 0;
        box-sizing: border-box;
        font-size: 20px;
        padding: 0 !important;
        outline: none;
        border: none;
        background: linear-gradient(to right, $light-blue, $dark-blue);

        .button-title {
            color: $light-blue;
            background-color: $black;
            white-space: nowrap;
            padding: 16px 24px;
            box-sizing: border-box;
            width: calc(100% - 4px);
            height: calc(100% - 4px);
            margin: 2px 2px;
            cursor: pointer;
            text-transform: uppercase;
        }

        &:hover {
            background: linear-gradient(to right, $dark-blue, $light-blue);
        }

    }

    .container {
        transform-origin: 50% 50%;
        transform: scale(1);
        opacity: 1;
        filter: blur(0px);
        visibility: visible;
        transition: all .3s ease;

        &.container-hidden {
            transform: scale(.95);
            opacity: 0;
            filter: blur(5px);
            visibility: hidden;
        }

        &.column-container {
            display: flex;
            flex-direction: column;
        }

        section {
            min-height: 100vh;
            width: 100%;
            display: flex;
            flex-direction: column;
            position: relative;
            margin: 24px 0;
            padding: 24px 0;

            &.hero {
                height: 100vh;

                .rock-container {
                    margin: auto;
                    position: relative;
                    transform: translateY(-64px);

                    #rock-canvas {
                        position: absolute;
                        top: 50%; left: 50%;
                        transform: translateX(-50%) translateY(-50%);
                    }

                    .hero-text {
                        position: relative;
                        z-index: 1;
                        color: white;
                        display: flex;

                        .left {
                            font-family: 'Sarpanch-Regular', sans-serif;
                            font-size: 24px;
                            height: 40px;
                            position: relative;
                            display: flex;
                            align-items: center;


                        }

                        .right {
                            font-family: 'Sarpanch-Black', sans-serif;
                            font-size: 40px;
                            z-index: 1;
                            color: $light-blue;

                            .line {
                                &:first-child {
                                    display: flex;
                                    align-items: center;

                                    &:before {
                                        content: ' ';
                                        display: flex;
                                        height: 4px;
                                        width: 5vmin;
                                        margin: 0 16px;
                                        background: linear-gradient(to left, #00C9FF, #50E3C9);
                                    }
                                }
                            }
                        }
                    }
                }
            }

            &.material-section {

                .material-group {
                    display: inline-flex;
                    flex-direction: column;
                    justify-content: center;
                    margin: 48px auto 48px auto;
                    width: auto;
                    max-width: 80vw;
                    position: relative;
                    align-self: center;



                    * {
                        flex-shrink: 0;
                        box-sizing: border-box;
                    }

                    &:nth-of-type(3) {
                        margin-top: calc(2.5vw + 48px);
                    }

                    > .name {
                        font-size: 2vw;
                        font-family: 'Sarpanch-Regular', sans-serif;
                        color: $light-blue;
                        text-transform: uppercase;
                        text-align: center;
                        margin: 16px 0 32px 0;
                        position: absolute;
                        top: 0; left: 50%;
                        transform: translateY(calc(-3vmin - 48px)) translateX(-50%);
                        white-space: nowrap;
                    }

                    .materials {
                        display: flex;
                        flex-direction: row;
                        width: auto;
                        margin: 16px auto;
                        position: relative;

                        .line {
                            display: flex;
                            width: 100%;
                            height: 1px;
                            margin: 0 auto;
                            background-color: $paper-grey;
                            position: absolute;
                            top: 0;  left: 0;

                            &:before, &:after {
                                content: '';
                                height: 10px; width: 10px;
                                display: flex;
                                background-color: lighten($light-blue, 10%);
                                position: absolute;
                                top: 50%;
                                border-radius: 50%;
                            }

                            &:before {
                                left: 0;
                                transform: translateX(-50%) translateY(-50%);
                            }

                            &:after {
                                right: 0;
                                transform: translateX(50%) translateY(-50%);
                            }
                        }

                        .material {
                            display: flex;
                            flex-direction: column;
                            align-items: center;

                            &:hover {
                                img {
                                    transform: scale(1.25);
                                }
                            }

                            &:not(:last-child) {
                                margin-right: 2.5vw;
                            }

                            &:before {
                                content: '';
                                display: flex;
                                height: 10vmin;
                                width: 1px;
                                background-color: $paper-grey;

                            }

                            .image-container {
                                height: 14vw;
                                width: 14vw;
                                padding: 2.5vw;
                                border-radius: 50%;
                                box-sizing: border-box;
                                border: 1px solid $paper-grey;
                                position: relative;
                                cursor: pointer;

                                img {
                                    height: 100%;
                                    width: 100%;
                                    object-fit: contain;
                                    object-position: center;
                                    transition: transform .2s ease;
                                }
                            }

                            .name {
                                font-family: 'Sarpanch-Regular', sans-serif;
                                margin-top: 2vw;
                                font-size: 1.75vw;
                                color: $light-blue;
                            }

                        }
                    }

                }
            }

            &.about-us-section {
                .general-text {
                    width: 80vw;
                    margin: 56px auto;

                    .text {
                        font-family: 'Sarpanch-Regular', sans-serif;
                        color: $paper-grey;
                        line-height: 1.5;
                    }

                    .button {
                        margin: 32px 0 16px 0;
                        display: inline-flex;
                        box-sizing: border-box;
                        font-size: 20px;
                        background: linear-gradient(to right, $light-blue, $dark-blue);

                        .button-title {
                            color: $light-blue;
                            background-color: $black;
                            white-space: nowrap;
                            padding: 16px 24px;
                            box-sizing: border-box;
                            width: calc(100% - 4px);
                            height: calc(100% - 4px);
                            margin: 2px 2px;
                            cursor: pointer;
                        }

                        &:hover {
                            background: linear-gradient(to right, $dark-blue, $light-blue);
                        }
                    }
                }

                .values {
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: space-between;
                    width: 80vw;
                    margin: 56px auto;

                    .value {
                        width: calc(50% - 16px);
                        min-height: 156px;
                        margin: 0 16px 32px 0;
                        display: flex;

                        img {
                            width: 100%;
                            height: 100%;
                            object-fit: contain;
                        }

                        .information {
                            width: 80%;
                            color: $paper-grey;
                            padding: 16px 16px 16px 24px;
                            font-family: 'Sarpanch-Regular', sans-serif;

                            .title {
                                font-size: 24px;
                                margin-bottom: 16px;
                                text-transform: uppercase;
                            }

                            ul {
                                li {
                                    line-height: 2.5;
                                }
                            }
                        }



                    }

                }
            }

            &.contact-us-section {
                position: relative;

                .contact-us-bg {
                    height: 100%;
                    width: 100%;
                    position: absolute;
                    top: 0; left: 0;
                    object-fit: cover;
                    opacity: .2;
                }

                .columns {
                    display: flex;
                    justify-content: space-between;
                    width: 80vw;
                    margin: 56px auto;

                    .column {
                        display: flex;
                        flex-direction: column;
                        position: relative;
                        width: calc(50% - 16px);

                        form {
                            display: flex;
                            flex-direction: column;

                            input, textarea {
                                background: transparent;
                                border: none;
                                border-bottom: 1px solid transparentize($paper-grey, .5);
                                resize: none;
                                font-size: 16px;
                                line-height: 32px;
                                margin-bottom: 16px;
                                color: $paper-grey;
                                outline: none;

                                &.half-length {
                                    width: 50%;
                                }

                                &:focus {
                                    border-bottom: 1px solid $paper-grey;
                                }

                                @include placeholder {
                                    text-transform: uppercase;
                                    opacity: 1;
                                }

                            }
                        }

                        .our-catalogue {
                            margin-left: 80px;

                            .title {
                                font-size: 20px;
                                color: $paper-grey;
                                margin-bottom: 16px;
                                font-family: 'Sarpanch-Regular', sans-serif;
                            }

                            .download-catalogue-link {
                                transition: transform .2s ease-in;

                                &:hover {
                                    transform: scale(1.05);
                                }
                            }

                            .graphic {
                                border-left: 2px solid $light-blue;
                                position: relative;
                                display: flex;
                                height: 300px;

                                &:before, &:after {
                                    z-index: 1;
                                    content: '';
                                    position: absolute;
                                    left: 0;
                                    top: 0;
                                    height: 2px;
                                    width: 100%;
                                    background: linear-gradient(to right, $light-blue, transparentize($dark-blue, 1));
                                }

                                &:after {
                                    top: calc(100% - 2px);
                                }

                                img {
                                    margin: auto 0;
                                    height: 75%;
                                    object-fit: contain;
                                    transform: translateX(-80px);
                                    position: relative;
                                }
                            }

                            a {
                                display: flex;
                                margin-top: 16px;
                                color: lighten($light-blue, 10%);
                            }
                        }

                        .address {
                            .title {
                                color: lighten($light-blue, 10%);
                                margin-bottom: 16px;
                                text-transform: uppercase;
                            }

                            ul {
                                li {
                                    color: white;
                                    line-height: 1.5;
                                }
                            }
                        }
                    }
                }
            }

            .sub-section-title {
                display: flex;
                align-items: center;

                > div {
                    flex-grow: 3;
                }

                .line {
                    height: 1px;
                    background-color: $paper-grey;
                    position: relative;

                    &:after {
                        content: '';
                        height: 10px; width: 10px;
                        display: flex;
                        background-color: lighten($light-blue, 10%);
                        position: absolute;
                        top: 50%;
                        border-radius: 50%;
                    }

                    &.line-left {
                        &:after {
                            left: 100%;
                            transform: translateX(-50%) translateY(-50%);
                        }
                    }

                    &.line-right {
                        &:after {
                            left: 0;
                            transform: translateX(-50%) translateY(-50%);
                        }
                    }
                }

                .title-text {
                    flex-grow: 1;
                    text-align: center;
                    padding: 0 16px;
                    font-size: 24px;
                    @include font-gradient();
                    font-family: 'Sarpanch-Regular', sans-serif;

                }
            }

            .section-title {
                display: flex;
                align-items: center;
                margin-bottom: 24px;

                .line {
                    height: 2px;
                    width: 20vmin;
                    background-color: white;
                    margin-right: 24px;
                    position: relative;

                    &:after {
                        content: '';
                        position: absolute;
                        top: 50%; left: 100%;
                        height: 16px; width: 16px;
                        border-radius: 50%;
                        transform: translateX(-50%) translateY(-50%);
                        background-color: lighten($light-blue, 10%);
                    }
                }

                .text {
                    font-family: 'Sarpanch-Bold', sans-serif;
                    font-size: 56px;
                    background: -webkit-linear-gradient(left, $light-blue, $dark-blue);
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                }
            }

            .side-graphic {
                position: absolute;
                top: 0; right: 32px;
                display: flex;
                align-items: center;
                height: 100%;
                width: 24px;
                --line-distance: 200px;

                &:after {
                    content: '';
                    position: absolute;
                    top: var(--line-distance); right: 0;
                    width: 1px;
                    background-color: transparentize($paper-grey, .76);
                    height: calc(100% - var(--line-distance));
                }

                .graphic-header {
                    display: flex;
                    position: absolute;
                    top: 0;
                    right: 0;
                    transform-origin: 100% 0;
                    transform: rotate(-90deg) translateY(-50%);
                    align-items: center;
                    z-index: 1;
                    padding: 0 24px;

                    .title {
                        color: $paper-grey;
                        font-size: 20px;
                        font-family: 'Sarpanch-Regular', sans-serif;
                        white-space: nowrap;
                    }

                    .logo {
                        height: 42px;
                        transform: rotate(90deg);
                        margin-left: 32px;

                        img {
                            height: 100%;
                        }
                    }
                }




            }
        }

        footer {
            margin: 32px auto;
            display: flex;
            flex-direction: column;
            width: 80vw;
            padding: 24px 0;

            .logo {
                border-top: 1px solid transparentize($paper-grey, .5);
                border-bottom: 1px solid transparentize($paper-grey, .5);
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 16px;

                img {
                    height: 72px;
                    margin-right: 16px;
                }

                span {
                    @include font-gradient();
                    font-family: 'Sarpanch-ExtraBold', sans-serif;
                    color: $light-blue;
                    font-size: 24px;
                    text-transform: uppercase;
                }
            }

            .disclaimer {
                text-align: center;
                padding: 24px 0;
                color: $paper-grey;
            }

        }
    }

    .material-preview {
        position: fixed;
        top: 64px; left: 0;
        width: 100vw;
        height: calc(100vh - 64px);
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        padding: 16px;
        z-index: -1;
        transform: scale(.95);
        opacity: 0;
        filter: blur(5px);
        visibility: hidden;
        transition: all .3s ease;

        &.active {
            transform-origin: 50% 50%;
            transform: scale(1);
            opacity: 1;
            filter: blur(0px);
            visibility: visible;
            z-index: 10;

        }

        .material-slider {
            position: absolute;
            left: 0; top: 0;
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;

            .group-name {
                position: absolute;
                top: 16px; left: 50%;
                font-family: 'Sarpanch-Regular', sans-serif;
                transform: translateX(calc(-50% + 72px));
                font-size: 24px;
                color: $paper-grey;
                padding: 8px 16px;
                border: 1px solid $paper-grey;
            }

            .group-connecting-line {
                width: 72px;
                height: 1px;
                background-color: transparentize(white, .76);
                position: absolute;
                top: calc(18.75vh); left: 50%;

                &:before, &:after {
                    content: ' ';
                    position: absolute;
                    width: 1px;
                    height: calc(9.5vh - 16px);
                    top: 0; left: 0;
                    background-color: transparentize(white, .76);
                }

                &:after {
                    left: initial;
                    right: 0;
                    transform: translateY(-100%);
                }
            }

            .slides {
                height: 25%;
                width: 100%;
                position: relative;

                .material-slide {
                    position: absolute;
                    left: 0; top: 0;
                    display: flex;
                    height: 100%;
                    width: 60%;
                    margin: 0 20%;
                    border: 2px solid $paper-grey;
                    visibility: hidden;

                    &.active {
                        visibility: visible;
                    }

                    &.slide-in-from-right {
                        visibility: visible;
                        animation: slideInFromRight .3s ease;

                        @keyframes slideInFromRight {
                            from {
                                transform: translateX(100%) scale(.8);
                                opacity: 0;

                            }
                            to {
                                transform: translateX(0) scale(1);
                                opacity: 1;
                            }
                        }
                    }

                    &.slide-in-from-left {
                        animation: slideInFromLeft .3s ease;
                        visibility: visible;

                        @keyframes slideInFromLeft {
                            from {
                                transform: translateX(-100%) scale(.8);
                                opacity: 0;

                            }
                            to {
                                transform: translateX(0) scale(1);
                                opacity: 1;
                            }
                        }
                    }

                    &.slide-out-to-right {
                        animation: slideOutToRight .3s ease;
                        visibility: visible;

                        @keyframes slideOutToRight {
                            from {
                                transform: translateX(0) scale(1);
                                opacity: 1;

                            }
                            to {
                                transform: translateX(100%) scale(0.75);
                                opacity: 0;
                            }
                        }
                    }

                    &.slide-out-to-left {
                        animation: slideOutToLeft .3s ease;
                        visibility: visible;

                        @keyframes slideOutToLeft {
                            from {
                                transform: translateX(0) scale(1);
                                opacity: 1;

                            }
                            to {
                                transform: translateX(-100%) scale(0.8);
                                opacity: 0;
                            }
                        }
                    }

                    .material-name {
                        font-family: 'Sarpanch-Regular', sans-serif;
                        font-size: 56px;
                        color: $paper-grey;
                        position: absolute;
                        top: 0; left: 50%;
                        transform: translateX(-50%) translateY(calc(-100% - 16px));
                    }

                    img {
                        height: 160%;
                        width: auto;
                        max-width: 40vmin;
                        object-fit: contain;
                        position: absolute;
                        top: 0; left: 0;
                        transform: translateX(-50%) translateY(-30%);
                    }

                    .material-description {
                        position: absolute;
                        top: 0; left: 20%;
                        height: 100%;
                        width: 80%;
                        padding: 16px;
                        font-family: 'Sarpanch-Regular', sans-serif;
                        font-size: 16px;
                        line-height: 24px;
                        color: $paper-grey;
                        box-sizing: border-box;
                    }
                }
            }

            .data-sheet-button {
                position: absolute;
                bottom: 18vh; right: 20%;

                &:before, &:after {
                    content: ' ';
                    position: absolute;
                    right: calc(100% + 16px); top: 50%;
                    background-color: transparentize(white, .74);
                    height: 1px; width: 72px;
                }

                &:before {
                    transform: translateY(-50%);
                }

                &:after {
                    height: 9.5vh; width: 1px;
                    right: calc(100% + 88px);
                    transform: translateY(calc(-100% + 0.5px));
                }

            }
        }

        .controls {
            display: flex;
            justify-content: space-between;
            position: absolute;
            top: 50%; left: 0;
            width: calc(100% - 32px);
            padding: 0 16px;
            box-sizing: border-box;
            transform: translateY(-50%);
            z-index: 1;

            i {
                color: white;
                font-size: 32px;
                border-radius: 50%;
                cursor: pointer;
                padding: 8px;

                &.disabled {
                    visibility: hidden;
                    pointer-events: none;
                }

                &:hover {
                    background-color: transparentize($paper-grey, .84);
                }
            }
        }

        .btn-close-material-preview {
            color: $paper-grey;
            font-size: 32px;
            position: absolute;
            top: 16px; left: 16px;

        }
    }

    @media screen and (max-width: 567px) {

        nav {
            .left-interactive-container {
                .name {
                    display: none;
                }
            }
        }

        .container {
            section {
                &.hero {
                    .rock-container {
                        .hero-text {
                            flex-direction: column;
                            align-items: center;

                            .left {
                                margin-bottom: 8px;
                            }

                            .right {

                                .line {
                                    display: flex;
                                    flex-direction: column;

                                    &:first-child {
                                        &:before {
                                            margin-bottom: 8px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                &.material-section {
                    .material-group {
                        margin: 16px auto;

                        > .name {
                            font-size: 16px;
                            margin: 8px 0 16px 0;
                            transform: translateX(-50%) translateY(-24px);
                        }

                        .materials {
                            .material {
                                > .name {
                                    font-size: 10px;
                                }
                            }
                        }
                    }
                }
                &.about-us-section {
                    .values {
                        display: flex;
                        flex-direction: column;

                        .value {
                            flex-direction: column;
                            width: 100%;
                            padding: 0 16px;
                            box-sizing: border-box;
                            margin: 0 0 16px 0;

                            .graphic {
                                height: 100px;
                            }

                            .information {
                                > div {
                                    text-align: center;
                                }

                                ul {
                                    list-style-type: disc;
                                    text-align: left;
                                }
                            }
                        }
                    }
                }
                &.contact-us-section {
                    .columns {
                        display: flex;
                        flex-direction: column;
                        margin: 24px auto;

                        .column {
                            width: calc(100% - 16px);
                            margin-right: 16px;
                            order: 1;

                            form {
                                input {
                                    &.half-length {
                                        width: 100%;
                                    }
                                }
                            }

                            &:first-child {
                                order: 2;
                            }

                            .our-catalogue {
                                margin: 0;

                                .graphic {
                                    border-left: none;

                                    &:before, &:after {
                                        display: none;
                                    }

                                    img {
                                        max-height: 33vh;
                                        width: 100%;
                                        transform: translateX(0);
                                    }
                                }
                            }
                        }
                    }
                }
                .section-title {
                    .line {
                        width: 10vmin;
                    }

                    .text {
                        font-size: 32px;
                    }
                }

            }
        }

        .material-preview {

            .controls {
                padding: 0 8px;
                width: 100%;
            }

            .material-slider {
                .group-name {
                    left: 0;
                    transform: translateX(0);
                    border: none;
                    padding: 8px 16px;
                    font-size: 20px;
                }

                .group-connecting-line {
                    display: none;
                }

                .slides {
                    height: 100%;
                    margin-top: 56px;

                    .material-slide {
                        border: none;
                        display: flex;
                        flex-direction: column;
                        width: 100%;
                        padding: 8px 16px;
                        box-sizing: border-box;
                        margin: 0 auto;

                        .material-name {
                            position: relative;
                            transform: translateX(0) translateY(0);
                            font-size: 24px;
                            left: initial;
                        }

                        img {
                            margin: 16px auto;
                            position: relative;
                            width: calc(100% - 32px);
                            max-height: 30vh;
                            max-width: initial;
                            height: auto;
                            transform: translateX(0) translateY(0);
                        }

                        .material-description {
                            position: relative;
                            left: initial; top: initial;
                            padding: 16px 40px;
                            width: 100%;
                            height: fit-content;
                            box-sizing: border-box;
                            font-size: 14px;
                        }
                    }
                }

                .data-sheet-button {
                    position: fixed;
                    left: 16px; bottom: 16px;
                    margin: 0;
                    width: calc(100% - 32px);
                }
            }
        }
    }
}