.materials {
  flex-direction: column;

  h2 {
    margin: 72px 0 24px 0;
    text-align: center;
    color: white;
    font-size: 56px;
    font-family: $roboto-regular;
    text-transform: uppercase;
  }

  .material-collections {
    display: flex;
    justify-content: center;
    flex-direction: column;
    opacity: 1;

    &.hidden {
      display: none;
    }

    &.hiding {
        transition: transform .3s ease, opacity .3s ease;
        animation: hideMaterialCollections .3s ease;

        @keyframes hideMaterialCollections {
          from {
            transform: translateY(0);
            opacity: 1;
          }
          to {
            transform: translateY(32px);
            opacity: 0;
          }
        }
    }

    &.showing {
      transition: transform .3s ease, opacity .3s ease;
      animation: showMaterialCollections .3s ease;

      @keyframes showMaterialCollections {
        from {
          transform: translateY(32px);
          opacity: 0;
        }
        to {
          transform: translateY(0px);
          opacity: 1;
        }
      }
    }

    .collection {
      display: flex;
      flex-direction: column;
      justify-items: center;
      margin: 24px 0;

      &.single-item {

        .material-line {
          border-bottom: none;
        }
      }

      .material-line {
        display: flex;
        align-self: center;
        border-bottom: 1px solid white;

        .material {
          display: flex;
          flex-direction: column;
          align-items: center;
          margin: 0 24px;
          cursor: pointer;

          &:first-of-type {
            .leg {
              &:after {
                content: ' ';
                display: inline-block;
                height: 1px; width: 100px;
                position: absolute;
                top: 100%;
                left: -100px;
                background-color: $light-blue;
                z-index: 2;
              }
            }
          }

          &:last-of-type {
            .leg {
              &:after {
                content: ' ';
                display: inline-block;
                height: 1px; width: 100px;
                position: absolute;
                top: 100%;
                right: -100px;
                background-color: $light-blue;
                z-index: 2;
              }
            }
          }

          &:hover {
            img {
              opacity: 0.5;
            }
          }

          .title {
            font-size: 14px;
            font-family: $roboto-light;
            color: white;
            text-align: center;
            margin-top: 24px;
          }

          img {
            margin: 16px 0;
            height: 72px;
            width: 144px;
            object-fit: contain;
            transition: opacity .2s ease;
            will-change: opacity;
          }

          .leg {
            height: 24px; width: 1px;
            background-color: white;
            position: relative;
          }
        }
      }

      h3 {
        font-size: 24px;
        font-family: $roboto-light;
        color: white;
        text-transform: uppercase;
        text-align: center;
        margin-top: 16px;
      }

    }
  }

  .material-preview {
    max-width: 992px;
    margin: auto;

    &.hidden {
      display: none;
    }

    &.showing {
      animation: showMaterialPreview .3s ease;
      
      @keyframes showMaterialPreview  {
        from {
          transform: translateX(-32px);
          opacity: 0;
        }
        to {
          transform: translateX(0px);
          opacity: 1;
        }
      }
    }

    &.hiding {
      animation: hideMaterialPreview .3s ease;

      @keyframes hideMaterialPreview  {
        from {
          transform: translateX(0px);
          opacity: 1;
        }
        to {
          transform: translateX(-32px);
          opacity: 0;
        }
      }
    }

    .btn-close-material-preview {
      color: white;
      font-size: 32px;
      margin: 16px 16px;
      @include icon-button();
    }

    .preview-section {
      display: flex;
      width: 992px;
      max-width: calc(100vw - 32px);
      justify-content: center;

      img {
        width: 300px;
        object-fit: contain;
        object-position: top;
        margin: 0 40px 0 16px;
      }
      .information {
        margin: 0 16px 0 40px;
        color: white;

        .title {
          font-size: 56px;
          text-transform: uppercase;
          line-height: 72px;
        }

        .subtitle {
          font-size: 24px;
          line-height: 32px;
          text-transform: capitalize;
        }

        p {
          margin-top: 24px;
          font-size: 16px;
          line-height: 1.75;
          font-family: $roboto-light;
        }
      }
    }
  }

  @media screen and (max-width: 768px){

    .material-collections {
      .collection {
        padding: 16px;
        .material-line {
          width: 100%;
          flex-direction: column;
          border: none;
          order: 2;

          .material {
            flex-direction: row;
            align-items: center;
            margin: 0;

            .title {
              margin: 0 16px;
              order: 2;
              font-size: 20px;
              text-align: left;
            }

            .leg {
              display: none;
            }
          }
        }

        h3 {
          order: 1;
          text-align: left;
        }
      }
    }

    .material-preview {
      .preview-section {
        flex-direction: column;

        .image-section {
          display: flex;
          justify-content: center;

          img {
            margin: 0;
            max-width: 300px;
            height: 200px;
          }
        }

        .information {
          .title {
            margin-top: 16px;
            font-size: 32px;
          }

          .subtitle {
            font-size: 24px;
          }

          p {
            font-size: 14px;
          }
        }

      }
    }
  }
}

