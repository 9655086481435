.contact {
  max-width: 992px;
  margin: auto;
  display: flex;
  flex-direction: column;
  color: white;

  h1 {
    font-size: 56px;
    line-height: 1.5;
    text-align: center;
    text-transform: uppercase;
  }

  .contact-groups {
    display: flex;
    justify-content: space-between;

    .contact-group {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 24px 0;
      width: 30%;

      i {
        font-size: 56px;
      }

      h2 {
        font-size: 32px;
        line-height: 2;
      }

      p, li {
        font-size: 16px;
        font-family: $roboto-light;
        line-height: 1.5;
      }

      a {
        color: white;
        transition: opacity .2s ease;
        will-change: opacity;
        cursor: pointer;

        &:hover {
          opacity: 0.5;
        }
      }
    }
  }

  @media screen and (max-width: 768px){
    .contact-groups {
      flex-direction: column;

      .contact-group {
        width: calc(100vw - 32px);
      }
    }
  }
}