.rippled {

  &.light {
    .ripple {
      background-color: white;
    }
  }

  .ripple {
    height: 20vmin; width: 20vmin;
    position: absolute;
    transform: translateX(-50%) translateY(-50%) scale(0);
    border-radius: 50%;
    background-color: black;
    animation: ripple .4s ease;

    @keyframes ripple {
      from {
        transform: translateX(-50%) translateY(-50%) scale(0.3);
        opacity: 0.3;
      }
      to {
        transform: translateX(-50%) translateY(-50%) scale(1.3);
        opacity: 0;
      }
    }
  }


}

