.obfuscator {
  height: 100vh; width: 100vw;
  background-color: rgba(0,0,0,.5);
  position: fixed;
  top: 0; left: 0;
  visibility: hidden;
  opacity: 0;
  transition: opacity .3s ease;
  will-change: opacity;
  z-index: 2;

  &.hiding {
    opacity: 0;
    visibility: visible;
  }

  &.active {
    visibility: visible;
    opacity: 1;
  }

}