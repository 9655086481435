.loader-container {
  height: 100vh; width: 100vw;
  top: 0; left: 0;
  position: fixed;
  z-index: 13;
  background-color: $paper-grey;
  display: flex;
  opacity: 1;
  transition: opacity .3s ease;
  will-change: opacity;

  &.hiding {
    opacity: 0;
  }

  &.hidden {
    display: none;
  }

  .loader {
    width: 100px;
    display: none;
    flex-direction: column;
    margin: auto;

    img {
      width: 100%;
      height: auto;
      object-fit: contain;
    }

    &.load-starting {
      display: flex;
      svg {
        #logo {
          .drop-container {
            visibility: hidden;
            opacity: 0;
            animation: showDrop .3s .3s ease-in forwards;

            @keyframes showDrop {
              from {
                visibility: visible;
                opacity: 0;
                transform: translateY(-5px) translateX(-5px);
              }
              to {
                visibility: visible;
                opacity: 1;
                transform: translateY(0) translateY(0);
              }
            }
          }


          #base {
            animation: showBase 0.3s ease-out forwards;
            transform-origin: 50% 50%;

            @keyframes showBase {
              0% {
                transform: scale(0);
              }

              100% {
                transform: scale(1);
              }
            }
          }
        }
      }
    }

    &.loading {
      display: flex;
      animation: bouncing 0.75s ease-in-out alternate infinite;
      @keyframes bouncing {
        from {
          transform: translateY(0);
        }
        to {
          transform: translateY(-15px);
        }
      }

      svg {
        #central_mountain {
          animation: mountainMove 2s ease-in-out alternate infinite;
          @keyframes mountainMove {
            from {
              transform: translateY(0);
            }
            to {
              transform: translateY(-3px);
            }
          }
        }
        #small_cloud {
          animation: cloudMove 2s ease-in-out alternate infinite;
          @keyframes cloudMove {
            from {
              transform: translateX(0);
            }
            to {
              transform: translateX(250%);
            }
          }
        }
      }
    }

    &.load-ending {
      display: flex;
      visibility: visible;
      animation: hideLoader .3s ease forwards;
      transform-origin: 50% 50%;
      @keyframes hideLoader {
        from {
          opacity: 1;
          transform: translateY(0);
        }
        to {
          opacity: 0;
          transform: translateY(-100%);
        }
      }
    }

  }
}
